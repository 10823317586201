<template>
  <!-- table -->
  <xm-flex>
    <xm-flex-item>
      <xm-search-box>
        <el-button size="small" type="primary" @click="add">新增类型</el-button>
      </xm-search-box>
    </xm-flex-item>
    <xm-flex-item><xm-total-count>{{`类型列表(${totalCount}条)`}}</xm-total-count></xm-flex-item>
    <xm-flex-item :flex="1">
      <xm-table-plus :query-data="queryData" ref="table">
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column label="状态" width="70">
          <template #default="scope">
            <el-tag type="primary" size="small" v-if="scope.row.flag === 1">显示</el-tag>
            <el-tag type="info" size="small" v-else>隐藏</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160">
          <template #default="scope">
            <el-button type="text" size="small" @click="modify(scope.row)">修改</el-button>
            <el-button type="text" size="small" @click="setFlag(scope.row.id, scope.row.flag)">{{scope.row.flag===1?'隐藏':'显示'}}</el-button>
            <el-dropdown @command="handleCommand">
              <el-button type="text" size="small" class="el-dropdown-link">更多</el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'up'}" v-if="!scope.row._isFirst">上移</el-dropdown-item>
                  <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'down'}" v-if="!scope.row._isLast">下移</el-dropdown-item>
                  <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'top'}" v-if="!scope.row._isFirst">置顶</el-dropdown-item>
                  <el-dropdown-item :command="{name: 'move', id: scope.row.id, action: 'bottom'}" v-if="!scope.row._isLast">置底</el-dropdown-item>
                  <el-dropdown-item :command="{name: 'clear', id: scope.row.id}">删除</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </xm-table-plus>
    </xm-flex-item>
  </xm-flex>
  <!-- dialog -->
  <el-dialog v-model="dialog.visible" :title="dialog.title" destroy-on-close>
    <el-form ref="ruleForm" :model="dialog.data" label-width="auto">
      <!-- 名称 -->
      <el-form-item label="名称" prop="name" :rules="[
        {required:true, message: '名称不能为空',trigger: 'blur'}
      ]">
        <el-input v-model="dialog.data.name" size="small" maxlength="128" clearable></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="dialog.visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="confirm" size="small">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { ElButton, ElDialog, ElTableColumn, ElForm, ElFormItem, ElInput, ElDropdown, ElDropdownItem, ElDropdownMenu, ElTag } from 'element-plus'
import axios from '@/common/utils/axios'
import { onMounted, ref, reactive, inject } from 'vue'
function table (type) {
  /**
     * 表单信息
     */
  const table = ref(null)
  const totalCount = ref(0)
  /**
     * 查询表单列表
     */
  const queryData = (page, pageSize) => {
    return new Promise((resolve) => {
      axios('/manager/template/group/list', {
        type,
        page,
        pageSize
      })
        .then(res => {
          totalCount.value = res.totalCount
          table.value.judgePosition(res.templateGroupList, res.totalCount)
          resolve({
            list: res.templateGroupList,
            totalCount: res.totalCount
          })
        }).catch(_ => { })
    })
  }

  onMounted(async () => {
    table.value.reload()
  })

  return {
    table,
    queryData,
    totalCount
  }
}

function dialog () {
  const ruleForm = ref(null)
  const dialog = reactive({
    visible: false,
    title: '',
    data: {}
  })
  return {
    ruleForm,
    dialog
  }
}

function operation (t, d, reloadTemplate, type) {
  /**
     * 新增
     */
  const add = () => {
    d.dialog.visible = true
    d.dialog.title = '新增模板类型'
    d.dialog.data = {
      templateGroupId: '',
      name: '',
      type
    }
  }
  /**
     * 修改
     */
  const modify = (row) => {
    d.dialog.visible = true
    d.dialog.title = '修改模板类型'
    d.dialog.data = {
      templateGroupId: row.id,
      name: row.name,
      type
    }
  }
  /**
   * 保存图文模板
   */
  const confirm = () => {
    d.ruleForm.value.validate(valid => {
      if (!valid) {
        return
      }
      submit()
    })
  }
  const submit = () => {
    axios('/manager/template/group/save', d.dialog.data)
      .then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        d.dialog.visible = false
        t.table.value.reloadCurrent()
        reloadTemplate()
      }).catch(_ => { })
  }
  /**
   * 更改状态
   */
  const setFlag = (id, flag) => {
    axios('/manager/template/group/flag/set', {
      templateGroupId: id,
      flag: flag === 1 ? 0 : 1
    }).then(res => {
      window.$message({
        message: '操作成功',
        type: 'success'
      })
      t.table.value.reloadCurrent()
    })
  }
  /**
     * 指令列表
     */
  const handleCommand = (command) => {
    commandList[command.name](command.id, command.action)
  }
  const commandList = {
    clear: (id) => { // 删除
      window.$confirm('是否确认删除').then(() => {
        del(id)
      }).catch(_ => { })
    },
    move: (id, action) => {
      axios('/manager/template/group/move', {
        templateGroupId: id,
        move: action
      }).then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        t.table.value.reload()
      })
    }
  }
  /**
   * 删除
   */
  const del = (id) => {
    axios('/manager/template/group/del', {
      templateGroupId: id
    })
      .then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        t.table.value.reloadCurrent()
      }).catch(_ => { })
  }
  return {
    add,
    modify,
    confirm,
    setFlag,
    handleCommand
  }
}
export default {
  components: { ElButton, ElDialog, ElTableColumn, ElForm, ElFormItem, ElInput, ElDropdown, ElDropdownItem, ElDropdownMenu, ElTag },
  props: {
    type: {
      type: Number,
      default: 1
    }
  },
  setup (props) {
    const reloadTemplate = inject('reloadTemplate')
    const t = table(props.type)
    const d = dialog()
    const o = operation(t, d, reloadTemplate, props.type)
    return {
      ...t,
      ...d,
      ...o
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dropdown .el-button {
  margin-left: 10px;
}
</style>
