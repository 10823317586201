// 1表示企业模板，2表示名片模板，3表示图文模板，4表示商品海报模板，5表示名片海报模板，6表示文章海报模板，7表示表单海报模板，8表示图文组合，9表示商品消息模板， 10表示名片消息模板，11表示文章消息模板，12表示表单消息模板，13表示营销消息模板，14表示助力消息模板，15表示小程序模板，16表示商品模板
const templateGroupType = {
  institution: 1,
  card: 2,
  desc: 3,
  goodsSharePoster: 4,
  cardSharePoster: 5,
  articleSharePoster: 6,
  activitySharePoster: 7,
  descGroup: 8,
  goodsShareMsgPoster: 9,
  cardShareMsgPoster: 10,
  articleShareMsgPoster: 11,
  activityShareMsgPoster: 12,
  marketingMsgPoster: 13,
  assistMsgPoster: 14,
  mini: 15,
  goods: 16
}

// -1表示不限，0表示隐藏，1表示正常，2表示删除，3表示待使用
const flag = {
  all: -1,
  invisible: 0,
  normal: 1,
  delete: 2,
  available: 3
}

// 1表示图片，2表示文本
const posterTemplateItemType = {
  image: 1,
  text: 2
}
// 0表示轮播图（横条大），1表示轮播图（横条小），13表示轮播图（横条迷你），2表示方块（2列），3表示方块（3列），4表示方块组合，5表示圆角图标（1～5个），6表示方形图标（1～5个），7表示轮播图（横条中），8表示轮播图（方形），9表示轮播图（竖条），11表示悬浮固定栏位，12表示轮播图（全屏）
const bannerGroupTemplateType = {
  carouselBig: 0,
  carouselMiddle: 7,
  carouselSmall: 1,
  carouselSquare: 8,
  carouselVertical: 9,
  carouselFull: 12,
  carouselMini: 13,
  twoSquare: 2,
  threeSquare: 3,
  combineSquare: 4,
  roundIcon: 5,
  squareIcon: 6,
  fixed: 11
}

// 0表示审核成功，1表示审核被拒绝，2表示审核中，3表示已撤回，4表示审核延后
const thirdpartyTemplateRecordStatus = {
  success: 0,
  reject: 1,
  audit: 2,
  rollback: 3,
  delay: 4
}
// 1表示小程序模板，2表示商品模板
const miniTemplateType = {
  miniprogram: 1,
  goods: 2
}

function type () {
  return {
    templateGroupType,
    flag,
    posterTemplateItemType,
    bannerGroupTemplateType,
    thirdpartyTemplateRecordStatus,
    miniTemplateType
  }
}

export default type
